import React, { useEffect, useCallback, useState } from 'react'
import { graphql } from 'gatsby'
import { DateTime } from 'luxon'
import { PageContextPropsType } from '../../../../i18n/i18n'
import LayoutBlog from '../../../components/LayoutBlog'
import BlogAside from '../../../components/BlogAside'
import HeroSubPage from '../../../components/HeroSubPage'
import BlogPagination from '../../../components/BlogPagination'
import { BlogArticleDatas, BlogTagData, BlogArticleDataList } from '../../../types/blog'
import * as BlogTagPageStyles from '../../../styles/BlogTagPage.css'
import classNames from 'classnames/bind'
import { useLocation } from '@reach/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import SEO from '../../../components/SEO'
const cx = classNames.bind(BlogTagPageStyles)

type BlogSearchProps = {
  data: { newtBlogTag: BlogTagData; allNewtBlogArticle: BlogArticleDataList }
} & PageContextPropsType

export default function BlogSearch({ data, pageContext }: BlogSearchProps) {
  const { allNewtBlogArticle } = data
  const FormatDate = useCallback((date: string) => {
    const Date = DateTime.fromISO(date).setZone('Asia/Tokyo').toFormat('yyyy.MM.dd')
    return Date
  }, [])

  const router = useLocation()
  const q = router.search
  const [searchText, setSearchText] = useState(decodeURI(q).replace('?q=', '').replace('+', ' '))
  const [queryNumber, setQueryNumber] = useState<number>(1)
  const [allResultArticle, setAllResultArticle] = useState<BlogArticleDatas[]>([])
  const [slug, setSlug] = useState<string>('')
  const title = searchText
  const search = useCallback(() => {
    setSlug(`/blog/search/?q=${encodeURIComponent(searchText)}`)
    const dataFilter = allNewtBlogArticle.edges.filter((e) => {
      const target = `
        ${e.node.title}
        ${e.node.introduction}
        ${e.node.section.map((item) => item.data)}
      `
      return target.indexOf(searchText) !== -1
    })
    setAllResultArticle(dataFilter)
  }, [allResultArticle, searchText])

  useEffect(() => {
    const queryParamReplace = decodeURIComponent(q)
      .replace('?q=', '')
      .replaceAll('+', ' ')
      .replaceAll(/&page=[0-9].*/g, '')
    const queryNumberReplace = Number(q.split('=').pop())
    setSearchText(queryParamReplace)
    setQueryNumber(isNaN(queryNumberReplace) ? 1 : queryNumberReplace)
    search()
  }, [q, searchText])

  return (
    <LayoutBlog pageContext={pageContext}>
      <SEO
        title={'検索:' + title + ' | KARTE Blocks Blog'}
        description="KARTE Blocks Blogは、サイト制作・運用・成果改善のコツとKARTE Blocksの最新事例を発信しています。"
        path={slug}
      />
      <HeroSubPage />
      <div className={cx('Container')}>
        <div className={cx('Container_Inner')}>
          <div className={cx('Container_Search')}>
            <FontAwesomeIcon icon={faMagnifyingGlass} className={cx('Container_Head_Icon')} />
            <h1 className={cx('Container_Search_Title')}>{searchText} の検索結果</h1>
          </div>
          {allResultArticle.length !== 0 ? (
            <div className={cx('Container_Article')}>
              {allResultArticle.map(
                (item: BlogArticleDatas, index: number) =>
                  (queryNumber - 1) * 10 <= index &&
                  index < queryNumber * 10 && (
                    <a href={`/blog/` + item.node.slug + `/`} className={cx('Container_ArticleItem')} key={item.node._id}>
                      <img className={cx('Container_ArticleItem_Image')} src={item.node.thumbnail.src} width="500" />
                      <div className={cx('Container_ArticleItem_Content')}>
                        <p className={cx('Container_ArticleItem_Date')}>{FormatDate(item.node.meta.metaPublishDate)}</p>
                        <p className={cx('Container_ArticleItem_Title')}>{item.node.title}</p>
                        <div className={cx('Container_ArticleItem_Tags')}>
                          {item.node.tags.map((tag) => (
                            <div className={cx('Container_ArticleItem_Tag')} key={tag._id}>
                              <p>#{tag.name}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </a>
                  )
              )}
              {searchText !== '' && <BlogPagination page="search" searchNumber={queryNumber} totalCount={allResultArticle.length} />}
            </div>
          ) : (
            searchText !== '' && <p className={cx('Not_Article')}>検索結果が見つかりませんでした😵</p>
          )}
        </div>
        <BlogAside />
      </div>
    </LayoutBlog>
  )
}

export const query = graphql`
  query {
    allNewtBlogArticle(sort: { fields: meta___metaPublishDate, order: DESC }) {
      totalCount
      edges {
        node {
          _id
          title
          slug
          thumbnail {
            _id
            src
          }
          meta {
            metaPublishDate
          }
          tags {
            _id
            name
            slug
          }
          introduction
          section {
            ... on Markdown {
              data
            }
          }
        }
      }
    }
  }
`
